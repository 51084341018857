import React from 'react';
import { MdOutlineHome, MdOutlineFilter, MdOutlineMenuBook, MdMusicVideo, MdOutlineVideoCameraFront, MdOutlinePerson, MdHelpOutline, 
  MdOutlinePersonPin, MdOutlineHistory, MdAttachMoney, MdOutlineImage, MdVoiceChat } from "react-icons/md";

export const data = [
  {
    title: "HOME",
    icon: <MdOutlineHome size={26} />,
    link: "/",
  },
  {
    title: "Video_Effect",
    icon: <MdOutlineFilter size={22} />,
    link: "/NewVideoEffectTask",
  },
  {
    title: "Storybook",
    icon: <MdOutlineMenuBook size={22} />,
    link: "/NewStorybookTask",
  },
  {
    title: "Transcription",
    icon: <MdMusicVideo size={22} />,
    link: "/NewTranscriptionTask",
  },
  {
    title: "Video_STS",
    icon: <MdOutlineVideoCameraFront size={25} />,
    link: "/VideoSubtitleToSpeech",
  },
  {
    title: "Image_STS",
    icon: <MdOutlineImage size={26} />,
    link: "/ImageSubtitleToSpeech",
  },
  {
    title: "PRICING",
    icon: <MdAttachMoney size={25} />,
    link: "/Pricing",
  },
  {
    title: "ACCOUNT",
    icon: <MdOutlinePerson size={26} />,
    link: "/Account",
  },
  {
    title: "PRESENTER",
    icon: <MdOutlinePersonPin size={26} />,
    link: "/Presenter",
  },
  {
    title: "Task_History",
    icon: <MdOutlineHistory size={26} />,
    link: "/TaskHistory",
  },
  {
    title: "Voice_Samples",
    icon: <MdVoiceChat size={26} />,
    link: "/VoiceSamples",
  },
  {
    title: "HELP",
    icon: <MdHelpOutline size={26} />,
    link: "/Help",
  },
];
