import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import { t, setLocale } from 'react-i18nify';
import NotificationSystem from 'react-notification-system';
import DT from 'duration-time-conversion';
import isEqual from 'lodash/isEqual';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { FaceLandmarker, FilesetResolver } from "@mediapipe/tasks-vision";
import ReactGA from 'react-ga';

import { apiBaseUrl, getKeyCode, estimateSpeechDurationForText } from './utils';
import NotFound from './components/NotFound';
import Loading from './components/Loading';
import ProgressBar from './components/ProgressBar';
import Sub from './libs/Sub';
import Storage from './utils/storage';
import ActivateAccount from './components/ActivateAccount';
import ResetPassword from './components/ResetPassword';
import VpsHome from './components/VpsHome';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import NewVideoEffectTask from './components/NewVideoEffectTask';
import NewStorybookTask from './components/NewStorybookTask';
import NewTranscriptionTask from './components/NewTranscriptionTask';
import VideoSubtitleToSpeech from './components/VideoSubtitleToSpeech';
import ImageSubtitleToSpeech from './components/ImageSubtitleToSpeech';
import SubmitVideoSTSTask from './components/SubmitVideoSTSTask';
import SubmitImageSTSTask from './components/SubmitImageSTSTask';
import TaskResultSuccess from './components/TaskResultSuccess';
import Signin from './components/Signin';
import Signup from './components/Signup';
import SendPasswordResetLink from './components/SendPasswordResetLink';
import Account from './components/Account';
import Presenter from './components/Presenter';
import NewPresenter from './components/NewPresenter';
import EditPresenter from './components/EditPresenter';
import PresenterResultSuccess from './components/PresenterResultSuccess';
import TaskHistory from './components/TaskHistory';
import Pricing from './components/Pricing';
import Help from './components/Help';
import VoiceSamples from './components/VoiceSamples';

const storage = new Storage();

export default function App() {
    const subtitleHistory = useRef([]);
    const notificationSystem = useRef(null);
    const videoPlayer = useRef(null);
    //const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState('');
    const [processing, setProcessing] = useState(0);
    const [subtitle, setSubtitleOriginal] = useState([]);
    const [waveform, setWaveform] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [translate, setTranslate] = useState('en');
    const [carouselData, setCarouselData] = useState(null);
    const [isDefaultVideo, setIsDefaultVideo] = useState(true);
    const [lastScreen, setLastScreen] = useState('VpsHome');
    const [resultMessage, setResultMessage] = useState('');

    // Initialize Google Analytics
    ReactGA.initialize('G-D83H09ZP0M');

    // Language
    const defaultLang = storage.get('language') || navigator.language.toLowerCase() || 'en';
    setLocale(defaultLang);
    const [language, setLanguage] = useState(defaultLang);

    // All options
    const [options, setOptions] = useState({});

    // Update an option
    const setOption = useCallback(
        option => {
            setOptions({
                ...options,
                ...option,
            });
        },
        [options, setOptions],
    );

    // Get authenticated user data from the server once
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState({});
    const [sid, setSid] = useState('');
    useEffect(() => {  
        if (!sid || options.reloadUserAccount) {
            fetch(`${apiBaseUrl}/auth/login/success`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true
                }
            })      
            .then(response => {
                if (response.status === 200) return response.json();
                throw new Error("failed to authenticate user");
            })
            .then(responseJson => {
                setAuthenticated(true);
                setUser(responseJson.user);
                setSid(responseJson.sid);
                storage.set('lastSignin', new Date());
            })
            .catch(err => {
                console.log(err);
                setAuthenticated(false);
                setUser(null);
                setSid(null);
            })
            setOption({'reloadUserAccount': false});
        }
    }, [sid, options.reloadUserAccount]);

    // Get ttsLanguages data from the server once
    const [ttsLanguages, setTtsLanguages] = useState([]);
    useEffect(() => {  
        fetch(`${apiBaseUrl}/allTtsLanguages`)
        .then(response => response.json())
        .then(data => {
            setTtsLanguages(data);
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    // Get ttsVoices data from the server once
    const ttsVoices = useRef([]);
    useEffect(() => {  
        fetch(`${apiBaseUrl}/allTtsVoices`)
        .then(response => response.json())
        .then(data => {
            ttsVoices.current = data;
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    // Get predefined avatars from the server once
    const predefinedAvatars = useRef([]);
    useEffect(() => {  
        fetch(`${apiBaseUrl}/defaultPresenters`)
        .then(response => response.json())
        .then(data => {
            predefinedAvatars.current = data;
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    // Get user presenter names from the server once
    const [userPresenters, setUserPresenters] = useState([]);
    useEffect(() => {  
        if (sid || options.reloadUserPresenters) {
            fetch(`${apiBaseUrl}/userPresenterNames`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ sid: sid })
            })
            .then(response => response.json())
            .then(data => {
                setUserPresenters(data);
            })
            .catch(err => {
                console.log(err);
            })
            setOption({'reloadUserPresenters': false});
        }
    }, [sid, options.reloadUserPresenters]);

    // Initialize the face detector
    const faceDetector = useRef();
    const initializefaceDetector = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
      );
      //const myDetector = await FaceDetector.createFromOptions(vision, {
      //  baseOptions: {
      //    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
          //delegate: "GPU"
      //  },
      //  runningMode: "IMAGE"
      //});
      const myDetector = await FaceLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
        },
        runningMode: "IMAGE"
      });
      return myDetector;
    };
    useEffect(() => { 
        if (!faceDetector.current) {
            initializefaceDetector()
            .then(result => {
                faceDetector.current = result;
            })
        }
    }, []);

    // Get videoFeeds data from the server once
    const [videoFeeds, setVideoFeeds] = useState([]);
    useEffect(() => {  
        fetch(`${apiBaseUrl}/video/posts?limit=2`)
        .then(response => response.json())
        .then(data => {
            setVideoFeeds(data);
        })
        .catch(err => {
            console.log(err);
        })
    }, []);


    const notify = useCallback(
        (obj) => {
            // https://github.com/igorprado/react-notification-system
            const notification = notificationSystem.current;
            notification.clearNotifications();
            notification.addNotification({
                position: 'bc',
                message: obj.message,
                level: obj.level,
                dismissible: 'both',
                autoDismiss: ['success', 'warning'].includes(obj.level) ? 3 : 0,
            });
        },
        [notificationSystem],
    );

    const notificationStyle = {
        Containers: { // Override the container
            DefaultStyle: { // Applied to every notification, regardless of the notification level
                width: '50%',
            },
        },
        NotificationItem: { // Override the notification item
            DefaultStyle: { 
                fontSize: '1.5em',
            },
        }
    }
      
    
    const newSub = useCallback((item) => new Sub(item), []);
    const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle]);

    const formatSub = useCallback(
        (sub) => {
            if (Array.isArray(sub)) {
                return sub.map((item) => newSub(item));
            }
            return newSub(sub);
        },
        [newSub],
    );

    const setSubtitle = useCallback(
        (newSubtitle, saveToHistory = true) => {
            if (!isEqual(newSubtitle, subtitle)) {
                if (saveToHistory) {
                    if (subtitleHistory.current.length >= 1000) {
                        subtitleHistory.current.shift();
                    }
                    subtitleHistory.current.push(formatSub(subtitle));
                }
                storage.set('subtitle', JSON.stringify(newSubtitle));
                setSubtitleOriginal(newSubtitle);
            }
        },
        [subtitle, setSubtitleOriginal, formatSub],
    );

    const copySubs = useCallback(() => formatSub(subtitle), [subtitle, formatSub]);

    const clearSubs = useCallback((isVideo = true) => {
        if (isVideo) {
            setSubtitle([]);
            subtitleHistory.current.length = 0;
        } else {   //clar text only
            const subs = copySubs();
            for (let i = 0; i < subs.length; i++) {
                subs[i].text = '';
            }
            setSubtitle(subs);
        }
    }, [setSubtitle, subtitleHistory, copySubs]);

    // Update language
    const updateLang = useCallback(
        (value, reload) => {
            setLocale(value);
            setLanguage(value);
            storage.set('language', value);

            if (reload && !options.videoUrl && !options.subtitleUrl) {
                if (!authenticated) {  
                    //reload the default video, and subtitles if no user ones
                    window.location.reload();
                } else {
                    let hasUserSubtitle = true;
                    const localSubtitleString = storage.get('subtitle');
                    if (!localSubtitleString) {
                        try {
                            const localSubtitle = JSON.parse(localSubtitleString);
                            if (!localSubtitle.length || (localSubtitle.length === 1 && localSubtitle[0] === t('SUB_TEXT'))) {
                                hasUserSubtitle = false;
                            }
                        } catch (error) {
                            hasUserSubtitle = false;
                        }
                    } else {
                        hasUserSubtitle = false;
                    }
                    if (!hasUserSubtitle) {
                        //reload the default video, and subtitles if no user ones
                        window.location.reload();
                    }
                }
            }
        },
        [setLanguage, authenticated, options.videoUrl, options.subtitleUrl],
    );

    const undoSubs = useCallback(() => {
        const subs = subtitleHistory.current.pop();
        if (subs) {
            setSubtitle(subs, false);
        }
    }, [setSubtitle, subtitleHistory]);

    const checkSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const previous = subtitle[index - 1];
            return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.2;
        },
        [subtitle, hasSub],
    );

    const removeSub = useCallback(
        (sub, isVideo) => {
            const index = hasSub(sub);
            if (index < 0 || subtitle.length === 1) 
                return;
            const subs = copySubs();
            subs.splice(index, 1);
            if (!isVideo) {   //reduce time by 1 second for every subtitle starting from index
                for (let i = index; i < subs.length; i++) {
                    subs[i].start = DT.d2t(subs[i].startTime - 1);
                    subs[i].end = DT.d2t(subs[i].endTime - 1);
                }
            }
            setSubtitle(subs);
            if (index === currentIndex)
                setCurrentTime(subs[index-1].startTime + 0.1);
        },
        [hasSub, copySubs, subtitle, setSubtitle],
    );

    const addSub = useCallback(
        (index, sub, isVideo) => {
            const subs = copySubs();
            if (sub) {
                subs.splice(index, 0, formatSub(sub));
            } else {
                const previous = subs[index - 1];
                const start = previous ? DT.d2t(previous.endTime) : '00:00:00.001';
                const end = previous ? DT.d2t(previous.endTime + 1) : '00:00:01.001';
                let newsub;
                if (isVideo) {
                    newsub = new Sub({start: start, end: end, text: t('SUB_TEXT'), presenter: previous.presenter});
                    subs.splice(index, 0, newsub);
                } else {   //insert the new subtitle and increase time by 1 second for every subtitle after it
                    newsub = new Sub({start: start, end: end, text: t('SUB_TEXT'), fname: previous.fname, presenter: previous.presenter});
                    subs.splice(index, 0, newsub);
                    for (let i = index+1; i < subs.length; i++) {
                        subs[i].start = DT.d2t(subs[i].startTime + 1);
                        subs[i].end = DT.d2t(subs[i].endTime + 1);
                    }
                }
            }
            setSubtitle(subs);
            setCurrentTime(subs[index].startTime + 0.1);
        },
        [copySubs, setSubtitle, formatSub],
    );

    const updateSub = useCallback(
        (sub, obj, isVideo) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const subClone = formatSub(sub);
            if (obj.charPerSecond) {
                if (isVideo) {
                    const text = obj.text ? obj.text : sub.text;
                    const estimatedDuration = estimateSpeechDurationForText(text, obj.charPerSecond);
                    if (estimatedDuration > 0) {
                        subClone.endTime = subClone.startTime + estimatedDuration;
                    }
                }
                delete obj.charPerSecond;
            }
            if (obj.useAudio !== undefined) {
                subClone.useAudio = obj.useAudio;
            }
            if (obj.audioType) {
                subClone.audioType = obj.audioType;
            }
            if (obj.draudio) {
                subClone.draudio = obj.draudio;
            }
            Object.assign(subClone, obj);
            if (subClone.check) {
                subs[index] = subClone;
                setSubtitle(subs);
            }
        },
        [hasSub, copySubs, setSubtitle, formatSub],
    );

    const mergeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const next = subs[index + 1];
            if (!next) return;
            const merge = newSub({
                start: sub.start,
                end: next.end,
                text: sub.text.trim() + '\n' + next.text.trim(),
                presenter: sub.presenter
            });
            subs[index] = merge;
            subs.splice(index + 1, 1);
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle, newSub],
    );

    const splitSub = useCallback(
        (sub, start) => {
            const index = hasSub(sub);
            if (index < 0 || !sub.text || !start) return;
            const subs = copySubs();
            const text1 = sub.text.slice(0, start).trim();
            const text2 = sub.text.slice(start).trim();
            if (!text1 || !text2) return;
            const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(3);
            if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
            subs.splice(index, 1);
            const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
            subs.splice(
                index,
                0,
                newSub({
                    start: sub.start,
                    end: middleTime,
                    text: text1,
                    presenter: sub.presenter
                }),
            );
            subs.splice(
                index + 1,
                0,
                newSub({
                    start: middleTime,
                    end: sub.end,
                    text: text2,
                    presenter: sub.presenter
                }),
            );
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle, newSub],
    );

    const moveSub = useCallback(
        (index, isUp) => {
            const subs = copySubs();
            if (isUp && index > 0) {   //swap text, fname and presenter between current and previous subtitle
                const sub = new Sub({start: 0, end: 0, text: subs[index].text, fname: subs[index].fname, presenter: subs[index].presenter, 
                    useAudio: subs[index].useAudio, audioType: subs[index].audioType, draudio: subs[index].draudio});
                subs[index].text = subs[index-1].text;
                subs[index].fname = subs[index-1].fname;
                subs[index].presenter = subs[index-1].presenter;
                subs[index].useAudio = subs[index-1].useAudio;
                subs[index].audioType = subs[index-1].audioType;
                subs[index].draudio = subs[index-1].draudio;
                subs[index-1].text = sub.text;
                subs[index-1].fname = sub.fname;
                subs[index-1].presenter = sub.presenter;
                subs[index-1].useAudio = sub.useAudio;
                subs[index-1].audioType = sub.audioType;
                subs[index-1].draudio = sub.draudio;
            }
            if (!isUp && index < subs.length - 1) {   //swap text, fname and presenter between current and next subtitle
                const sub = new Sub({start: 0, end: 0, text: subs[index].text, fname: subs[index].fname, presenter: subs[index].presenter, 
                    useAudio: subs[index].useAudio, audioType: subs[index].audioType, draudio: subs[index].draudio});
                subs[index].text = subs[index+1].text;
                subs[index].fname = subs[index+1].fname;
                subs[index].presenter = subs[index+1].presenter;
                subs[index].useAudio = subs[index+1].useAudio;
                subs[index].audioType = subs[index+1].audioType;
                subs[index].draudio = subs[index+1].draudio;
                subs[index+1].text = sub.text;
                subs[index+1].fname = sub.fname;
                subs[index+1].presenter = sub.presenter;
                subs[index+1].useAudio = sub.useAudio;
                subs[index+1].audioType = sub.audioType;
                subs[index+1].draudio = sub.draudio;
            }
            setSubtitle(subs);
            const newIndex = isUp? index-1 : index+1;
            setCurrentTime(subs[newIndex].startTime + 0.1);
        },
        [copySubs, setSubtitle],
    );

    const shiftSubsToSolveConflict = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const previous = subs[index - 1];
            if (!previous) return;
            if (previous.endTime > sub.startTime) {
                const timeToShift = previous.endTime - sub.startTime;
                for (let i = index; i < subs.length; i++) {
                    subs[i].startTime += timeToShift;
                    subs[i].endTime += timeToShift;
                }
            }
            setSubtitle(subs);
        },
        [hasSub, copySubs, setSubtitle],
    );

    const onKeyDown = useCallback(
        (event) => {
            const keyCode = getKeyCode(event);
            switch (keyCode) {
                case 32:
                    event.preventDefault();
                    if (videoPlayer.current) {
                        if (playing) {
                            videoPlayer.current.pause();
                        } else {
                            videoPlayer.current.play();
                        }
                    }
                    break;
                case 90:
                    event.preventDefault();
                    if (event.metaKey) {
                        undoSubs();
                    }
                    break;
                default:
                    break;
            }
        },
        [videoPlayer, playing, undoSubs],
    );

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    useMemo(() => {
        let curIndex = subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime);
        if (curIndex < 0 && subtitle.length > 0)
            curIndex = 0;
        setCurrentIndex(curIndex);
    }, [currentTime, subtitle]);

    useEffect(() => {
        const localSubtitleString = storage.get('subtitle');
        //const lang = language.split('-')[0];
        //const defaultSubtitleFile = `/assets/tutorial_${lang}.json`;     //'/sample.json'
        const fetchSubtitle = () => {
            /*
            if (!options.videoUrl) {   //load the default subtitle file when no user video
                fetch(defaultSubtitleFile)
                    .then((res) => res.json())
                    .then((res) => {
                        setSubtitleOriginal(res.map((item) => new Sub(item)));
                    });
            }*/
            setSubtitle([
                newSub({
                    start: '00:00:00.000',
                    end: '00:00:01.000',
                    text: t('SUB_TEXT'),
                    presenter: '1'
                }),
            ]);
        }

        if (localSubtitleString) {
            try {
                const localSubtitle = JSON.parse(localSubtitleString);
                if (localSubtitle.length > 0) {
                    setSubtitleOriginal(localSubtitle.map((item) => new Sub(item)));
                } else {
                    fetchSubtitle();
                }
            } catch (error) {
                fetchSubtitle();
            }
        } else {
            fetchSubtitle();
        }
    }, []);



    const props = {
        //player,
        //setPlayer,
        subtitle,
        setSubtitle,
        waveform,
        setWaveform,
        currentTime,
        setCurrentTime,
        currentIndex,
        setCurrentIndex,
        playing,
        setPlaying,
        language,
        setLanguage,
        loading,
        setLoading,
        setProcessing,
        subtitleHistory,

        options,
        setOption,
        updateLang,

        authenticated,
        user,
        sid,
        ttsLanguages,
        ttsVoices,
        predefinedAvatars,
        userPresenters,
        faceDetector,
        videoFeeds,

        notify,
        newSub,
        hasSub,
        checkSub,
        removeSub,
        addSub,
        undoSubs,
        clearSubs,
        updateSub,
        formatSub,
        mergeSub,
        splitSub,
        copySubs,
        moveSub,
        shiftSubsToSolveConflict,

        translate, 
        setTranslate,

        carouselData,
        setCarouselData,
        isDefaultVideo, 
        setIsDefaultVideo,
        videoPlayer,

        lastScreen, 
        setLastScreen,
        resultMessage, 
        setResultMessage,
    };

    return (
        <React.Fragment>
            <Routes>
                <Route path="*" element={<NotFound {...props} />} />
                <Route path="/" element={<VpsHome {...props} />} />
                <Route path="/app" element={<VpsHome {...props} />} />
                <Route path="/home" element={<VpsHome {...props} />} />
                <Route path="/ActivateAccount/*" element={<ActivateAccount {...props} />} />
                <Route path="/ResetPassword/*" element={<ResetPassword {...props} />} />
                <Route path="/Contact/*" element={<Contact {...props} />} />
                <Route path="/PrivacyPolicy/*" element={<PrivacyPolicy {...props} />} />
                <Route path="/TermsOfService/*" element={<TermsOfService {...props} />} />
                <Route path="/NewVideoEffectTask/*" element={<NewVideoEffectTask {...props} />} />
                <Route path="/NewStorybookTask/*" element={<NewStorybookTask {...props} />} />
                <Route path="/NewTranscriptionTask/*" element={<NewTranscriptionTask {...props} />} />
                <Route path="/VideoSubtitleToSpeech/*" element={<VideoSubtitleToSpeech {...props} />} />
                <Route path="/ImageSubtitleToSpeech/*" element={<ImageSubtitleToSpeech {...props} />} />
                <Route path="/SubmitVideoSTSTask/*" element={<SubmitVideoSTSTask {...props} />} />
                <Route path="/SubmitImageSTSTask/*" element={<SubmitImageSTSTask {...props} />} />
                <Route path="/TaskResultSuccess/*" element={<TaskResultSuccess {...props} />} />
                <Route path="/Signin/*" element={<Signin {...props} />} />
                <Route path="/Signup/*" element={<Signup {...props} />} />
                <Route path="/SendPasswordResetLink/*" element={<SendPasswordResetLink {...props} />} />
                <Route path="/Account/*" element={<Account {...props} />} />
                <Route path="/Presenter/*" element={<Presenter {...props} />} />
                <Route path="/NewPresenter/*" element={<NewPresenter {...props} />} />
                <Route path="/EditPresenter/:number" element={<EditPresenter {...props} />} />
                <Route path="/PresenterResultSuccess/*" element={<PresenterResultSuccess {...props} />} />
                <Route path="/TaskHistory/*" element={<TaskHistory {...props} />} />
                <Route path="/Pricing/*" element={<Pricing {...props} />} />
                <Route path="/Help/*" element={<Help {...props} />} />
                <Route path="/VoiceSamples/*" element={<VoiceSamples {...props} />} />
            </Routes>
            {loading ? <Loading loading={loading} /> : null}
            {processing > 0 && processing < 100 ? <ProgressBar processing={processing} /> : null}
            <NotificationSystem ref={notificationSystem} allowHTML={true} style={notificationStyle} />
        </React.Fragment>
    );
}
