import React from "react";
import { t } from 'react-i18nify';

const brandsData = [
  {
    imageSrc: "/assets/brands/aws.svg",
    altText: "AWS",
    link: "https://aws.amazon.com/",
  },
  {
    imageSrc: "/assets/brands/microsoft.svg",
    altText: "Microsoft",
    link: "http://www.microsoft.com/",
  },
  {
    imageSrc: "/assets/brands/paypal.svg",
    altText: "PayPal",
    link: "https://www.paypal.com/",
  },
  {
    imageSrc: "/assets/brands/mongodb.svg",
    altText: "MongoDB",
    link: "https://www.mongodb.org/",
  },
  {
    imageSrc: "/assets/brands/digital-ocean.svg",
    altText: "Digital Ocean",
    link: "https://www.digitalocean.com/",
  },
  {
    imageSrc: "/assets/brands/invest-ottawa.png",
    altText: "Invest Ottawa",
    link: "https://www.investottawa.ca/",
  },
  {
    imageSrc: "/assets/brands/gnowit.png",
    altText: "Gnowit",
    link: "https://www.gnowit.com/",
  },
];

export default function Brands() {
  return (
    <section id="brands" className="relative overflow-hidden py-12">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-5 max-w-[520px] text-center">
              <h4 className="mb-4 text-xl font-bold">
                { t("Brands") }
              </h4>
            </div>
            <div className="mx-auto mb-4 flex flex-wrap items-center justify-center">
              {brandsData.map((brand, i) => (
                <SingleImage key={i} brand={brand} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const SingleImage = ({ brand }) => {
  const { link, imageSrc, altText } = brand;
  return (
    <>
      <a
        href={link}
        target="_blank" rel="noreferrer"
        className="mx-4 flex max-w-[200px] items-center justify-center py-2"
      >
        <img 
          loading="lazy" decoding="async" 
          src={imageSrc} alt={altText} 
          className="p-1 h-10 w-full rounded-lg dark:bg-slate-300" />
      </a>
    </>
  );
};
